.notification-drag-container {
    list-style: none;
    position: relative;
    background-color: #f24822;
    border-radius: 6px;
}

.msg-container {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
}

.delete-btn {
    z-index: 1;
    position: absolute;
    height: 100%;
    width: 70px;
    top: 50%;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(-50%);
    background-color: #f24822;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

.icon {
  filter: invert(100%) sepia(0%) saturate(2%) hue-rotate(307deg) brightness(107%) contrast(101%);
}

.blueIcon {
  filter: invert(20%) sepia(96%) saturate(1699%) hue-rotate(190deg) brightness(93%) contrast(98%);
}

.greenIcon {
  filter: invert(62%) sepia(8%) saturate(1862%) hue-rotate(118deg) brightness(94%) contrast(98%);
}

@font-face {
    font-family: "Futura Condensed Extra Bold";
    src:
        local("FuturaCondensedExtraBold"),
        url("./fonts/Futura\ Condensed\ Extra\ Bold.otf") format("opentype");
}

@font-face {
    font-family: "Heroes Den Bosch";
    src:
        local("HeroesDBRegular"),
        url("./fonts/HeroesDBRegular.otf") format("opentype");
}

@font-face {
    font-family: "Futura Medium Condensed";
    src:
        local("FuturaMediumCondensed"),
        url("./fonts/futura\ medium\ condensed\ bt.ttf") format("truetype");
}

@font-face {
    font-family: "ITC Avant Garde Std";
    src: url("./fonts/ITCAvantGardeStd-BoldObl.otf") format("truetype");
}

@font-face {
    font-family: "Breakers Slab";
    src: url("./fonts/Breakers-Slab.ttf") format("truetype");
}

@font-face {
    font-family: "Open Sans";
    src: url("./fonts/OpenSans-Regular.ttf") format("truetype");
}

@import url("https://fonts.googleapis.com/css2?family=Aleo:ital,wght@0,400;0,500;0,700;1,300&display=swap");

body {
    -webkit-font-smoothing: antialiased;
    font-synthesis: weight style;
    text-rendering: optimizeLegibility;

    margin: 0;

    overscroll-behavior: auto;
}

.white {
    color: white !important;
}

.link:hover {
    fill-rule: #4d94d6 !important;
}

.nav-item a.active {
    background: #3cb39d;
}
.nav-item a.active:before {
    border-left: none !important;
}

.link .active {
    fill-rule: #4d94d6 !important;
}
.hover-pointer:hover {
    cursor: pointer;
}

.popupCard {
    display: flex;
    flex-direction: column;
    width: 100%;
}
button.popupButton {
    font-family: Poppins, sans-serif;
    font-weight: 600;
    font-size: 14px;
    width: fit-content;
    text-transform: none;
    color: white;
    box-shadow: none;
    border-radius: 4px;
}

button.confirm {
    margin: 12px 10px 5px 16px;
    background-color: #54ab9a;
    color: white;
    transition: background-color 0.2s;
    text-transform: capitalize;
    font-family: Poppins, sans-serif;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 150.5px;
    height: 44px;
}
button.confirm:hover {
    background-color: #f6903c;
}
button.cancel {
    margin: 12px 0px 5px 10px;
    background-color: #dddbd7;
    color: #222323;
    transition: background-color 0.2s;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 150.5px;
    height: 44px;
}
button.cancel:hover {
    background-color: #0262b1;
}

.welcome-body {
    margin: 50px;
}

/* scrollbar */

/* width */
::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #dddbd7;
    border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* Homescreen */

a.nav-link.white.position-absolute {
    padding: 0;
}

/* Table no hover style */
.table {
    --bs-table-hover-color: none;
    --bs-table-hover-bg: none;
}

.text-transform-none {
    text-transform: none;
}
.font-weight-bold {
    font-weight: bold;
}

span.navbar-toggler-icon2.fe {
    color: white;
}

button.confirmDeleteAccount {
    background-color: white;
    border: 1px solid #dddbd7;
    border-radius: 4px;
    color: #ec3323;
    height: 44px;
}
button.cancelDeleteAccount {
    background-color: white;
    color: black;
    border-radius: 4px;
    height: 44px;
}

.couponPopup .MuiDialog-paper {
    border-radius: 6px !important;
}

/* To match bootstrap grid size (col-xs-*) Extra small <768px */
@media only screen and (max-width: 768px) {
    .navbar-brand.mb-2 {
        margin-bottom: 0rem !important;
    }
    .navbar-brand-img,
    .navbar-brand > img {
        max-height: 3rem;
    }

    a.nav-link.white {
        width: 100%;
    }
    .welcome-body {
        margin: 0px;
        margin-top: 20px;
    }
    .navbar-collapse:before {
        display: none;
    }
}

/* User snap iFrame */
iframe[name="us-entrypoint-widgetAppEmbed"] {
    margin-top: 30px;
    height: 300px !important;
    overflow: hidden;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.feedback-box {
    align-items: center;
    text-align: center;
}

.justifyCenter {
    justify-content: center !important;
    text-align: center;
}

.noSelect {
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}

.blur {
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
    background-color: #ccc;
}

.hide-scrollbar::-webkit-scrollbar {
    display: none;
}

.outline-text {
    color: transparent;
    -webkit-text-stroke: 1px #fffffff0; /* Width and color of the outline */
}

.shadow {
    transform: translateY(-15px);
}

.background-shadow::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
}

.background-fade {
    -webkit-mask-image: linear-gradient(rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0) 100%);
    mask-image: linear-gradient(rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0) 100%);
}

.text-fade {
    -webkit-mask-image: linear-gradient(rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0) 100%);
    mask-image: linear-gradient(rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0) 100%);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-background-clip: text;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px transparent !important;
    color: inherit !important;
    -webkit-text-fill-color: inherit !important;
}

input::-webkit-date-and-time-value {
    text-align: left;
}

.toolbar-button {
  display: flex;
  width: fit-content;
  margin-left: 30px;
  padding: 8px;
}

.green {
  filter: invert(75%) sepia(4%) saturate(5123%) hue-rotate(119deg) brightness(82%) contrast(76%);
}

.pulse {
  border-radius: 50%;
  height: 100px;
  width: 100px;
  box-shadow: 0 0 0 0 rgba(84, 171, 154, 1);
  transform: scale(1);
  animation: pulse 2s infinite;

  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes pulse {
  0% {
    transform: scale(0.75);
    box-shadow: 0 0 0 0 rgba(84, 171, 154, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(84, 171, 154, 0);
  }

  100% {
    transform: scale(0.75);
    box-shadow: 0 0 0 0 rgba(84, 171, 154, 0);
  }
}
